@font-face {
  font-family: 'MobiliarRealist Regular';
  src: url('../fonts/MobiliarRealistWeb-Regular.eot');
  src: url('../fonts/MobiliarRealistWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MobiliarRealistWeb-Regular.woff2') format('woff2'),
  url('../fonts/MobiliarRealistWeb-Regular.woff') format('woff'),
  url('../fonts/MobiliarRealistWeb-Regular.ttf')  format('truetype'),
  url('../fonts/MobiliarRealistWeb-Regular.svg#MobiliarRealistWeb') format('svg');
}

$xs-font-sizes: (
  null: 1.4rem,
  sm: 1.4rem,
  md: 1.4rem,
  lg: 1.4rem,
  xl: 1.4rem
);

$sm-font-sizes: (
  null: 1.6rem,
  sm: 1.6rem,
  md: 1.6rem,
  lg: 1.6rem,
  xl: 1.6rem
);

$body-font-sizes: (
  null: 1.6rem,
  sm: 1.6rem,
  md: 1.6rem,
  lg: 1.8rem,
  xl: 1.8rem
);

$lead-font-sizes: (
  null: 1.8rem,
  sm: 1.8rem,
  md: 1.8rem,
  lg: 2rem,
  xl: 2rem
);

$nav-font-sizes: (
  null: 2rem,
  sm: 2rem,
  md: 2rem,
  lg: 1.5rem,
  xl: 1.6rem
);

$h1-font-sizes: (
  null: 2.4rem,
  sm: 2.4rem,
  md: 2.4rem,
  lg: 2.8rem,
  xl: 2.8rem
);

$h2-font-sizes: (
  null: 2.2rem,
  sm: 2.2rem,
  md: 2.2rem,
  lg: 2.4rem,
  xl: 2.4rem
);

$h3-font-sizes: (
  null: 1.8rem,
  sm: 1.8rem,
  md: 1.8rem,
  lg: 2rem,
  xl: 2.2rem
);

$h4-font-sizes: $body-font-sizes;
$h5-font-sizes: $sm-font-sizes;
$h6-font-sizes: $xs-font-sizes;

$gamecenter-font-sizes: (
  null: 2.4rem,
  sm: 2.4rem,
  md: 2.4rem,
  lg: 2.2rem,
  xl: 1.9rem
);
