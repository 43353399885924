@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      @include media-breakpoint-up($fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

@mixin line-height($lh-map) {
  @each $lh-breakpoint, $lh-line-height in $lh-map {
    @if $lh-breakpoint == null {
      line-height: $lh-line-height;
    }
    @else {
      @media screen and (min-width: $lh-breakpoint) {
        line-height: $lh-line-height;
      }
    }
  }
}
