@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "1_settings/all";
@import "2_grid/all";
@import "3_mixins/all";

html {
  font-size: 10px;
}

body.login {
  background: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  background: $color-grey;
  @include font-size($body-font-sizes);

  h1 a {
    background: url('../img/jets-logo.png') no-repeat;
    height: 150px;
    width: auto;
    background-size: 150px 150px;
    background-position: center;
    margin-bottom: 2rem;
  }

  form {
    box-shadow: none;
    margin-top: 0;
    border: none;
    background: none;

    input.password-input,
    input[type=text] {
      border: 2px solid $jets-blue;
    }

    input {
      background: $white;
      box-shadow: none;
      padding: .9rem 1rem;
      border-radius: 0;
    }
  }

  &.wp-core-ui {
    .button.button-large {
      height: auto;
      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      font-size: 16px;
      line-height: 1;
      background: $jets-blue;
      border: none;
    }
  }

  #backtoblog a,
  #nav a {
    color: $jets-blue;
  }

}
